<template>
	<section class="logged-out">
		<StsCard>
			<section class="card-content">
				<BaseIcon title="Check Mark" height="34px" width="34px" color="var(--success)">
					<Check />
				</BaseIcon>
				<h1>You're signed out of the Loan Center.</h1>
			</section>
		</StsCard>

		<p>What you can do next</p>

		<aside class="content-container ribbon-grid">
			<a class="ribbon-item" title="Go to Online Account Opening" :href="openAccountLink" target="_blank">
				<StsOutlinedIcon name="Piggy Bank" title="Go to Online Account Opening" :width="iconDimension" :height="iconDimension">
					<PiggyBank />
				</StsOutlinedIcon>
				<span>Open An Account</span>
			</a>
			<a class="ribbon-item" title="Go to Desert Financial Homepage" :href="companyWebsiteLink" target="_blank">
				<StsOutlinedIcon name="Desert Financial Logo" title="Go to Desert Financial Homepage" :width="iconDimension" :height="iconDimension">
					<DFMark />
				</StsOutlinedIcon>
				<span>Return To Desert Financial</span>
			</a>
		</aside>

		<StsButton title="Sign In Again" type="plain" alternative small style="margin-top: 1rem" @click="goHome" />
	</section>
</template>





<script>
import { StsButton, StsCard, StsOutlinedIcon } from '@/components/SwitchThink';
import { DFMark } from '@/components/SwitchThink/logos';
import { BaseIcon, Check, Handshake, PiggyBank } from '@/components/SwitchThink/icons';
import { getDeviceSize, showSpinner } from '@/mixins';
import { mapActions } from 'vuex';
import { axios } from '@/utils';
import { toastConfig } from '@/utils';
import { createToastInterface } from 'vue-toastification';
const toast = createToastInterface(toastConfig);

export default {
	name: 'LoggedOut',
	mixins: [getDeviceSize, showSpinner],
	components: {
		StsButton,
		StsCard,
		StsOutlinedIcon,
		DFMark,
		BaseIcon,
		Check,
		Handshake,
		PiggyBank
	},
	data() {
		return {
			onlineBankingLink: '',
			openLoanLink: '',
			companyWebsiteLink: '',
			openAccountLink: ''
		};
	},
	computed: {
		iconDimension() {
			return this.isMobile ? 75 : 100;
		}
	},
	async created() {
		try {
			this.showSpinner({ loading: true });

			await this.logOut();

			const {
				data: { onlineBankingLink, openLoanLink, companyWebsiteLink, openAccountLink }
			} = await axios.get('/api/Configuration/ExternalLinks');

			this.onlineBankingLink = onlineBankingLink;
			this.openLoanLink = openLoanLink;
			this.companyWebsiteLink = companyWebsiteLink;
			this.openAccountLink = openAccountLink;
		} catch (error) {
			toast.error(error);
		} finally {
			this.showSpinner({ loading: false });
		}
	},
	methods: {
		...mapActions('applicant', ['logOut']),
		goHome() {
			return this.$router.push(this.$constructUrl('Authentication'));
		}
	}
};
</script>





<style lang="scss" scoped>
.logged-out {
	padding-top: 2.5rem;

	.card-content {
		display: flex;
		justify-content: center;
		align-items: center;

		h1 {
			font-size: 1.25rem;
			color: var(--primary);
			font-weight: 300;
			margin-left: 1rem;
		}
	}

	p {
		font-size: 0.91rem;
		color: var(--primary);
		font-weight: 700;
		margin: 1.25rem;
		text-align: center;
	}

	.ribbon-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 0.5em;
		grid-row-gap: 0.5rem;

		.ribbon-item,
		::v-deep .ribbon-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0.5rem;
			border-radius: 8px;
			border: 2px solid var(--disabled);
			color: var(--primary);
			font-weight: 300;
			font-size: 0.9rem;
			transition: opacity 1s;
			text-decoration: none;
			background-color: var(--white);

			&:hover {
				opacity: 0.7;
			}

			span {
				margin-top: 0.5rem;
			}
		}
	}
}

</style>